
import { defineComponent } from 'vue'

class Headerquote {
  caption: string
  keyword: string

  constructor(caption: string, keyword: string) {
    this.caption = caption
    this.keyword = keyword
  }
}

export default defineComponent({
  name: 'HomeView',
  setup() {
    return { }
  },

  data() {
    var headerindex = 0
    var keywordindex = 0
    
    const headers: Headerquote[] = [
      new Headerquote("your timer app for", "WORKOUTS"),
      new Headerquote("a buddy for", "TRAINING"),
      new Headerquote("made for", "HIIT"),
      new Headerquote("used for", "RUNNING"),
      new Headerquote("take it to the", "GYM"),
      new Headerquote("create simple & complex", "INTERVALS"),
      new Headerquote("have you ever tried", "POMODORO"),
      new Headerquote("a timer for your", "TABATA"),
      new Headerquote("keeps you in", "FOCUS"),
      new Headerquote("perfect for", "CARDIO"),
      new Headerquote("take it to", "CROSSFIT"),
      new Headerquote("step up your", "FITNESS"),
      // new Headerquote("focus on", "MEDITATION"),
      new Headerquote("an app for", "CALISTHENICS"),
      new Headerquote("boost your", "PRODUCTIVITY"),
      new Headerquote("good for training", "BOXING")
      ]
    
    const colors = [
      '#FFBD4F',
      '#FF6A75',
      '#AC71FF',
      '#00B3F5',
      '#3FE1B0',
      '#F564FF',
      '#EB5C47'
      // '#849324'
    ]

    return {
      headerindex,
      keywordindex,
      headers,
      colors
    }
  },

  methods: {
    randomTextGradient() {
      let col: string = this.colors[Math.floor(Math.random() * this.colors.length)]
      let col2: string = this.colors[Math.floor(Math.random() * this.colors.length)]

      return {
        color: col,
        background: 'linear-gradient(158deg, ' + col + ', ' + col2 + ')',
        'background-clip': 'text !important',
        '-webkit-background-clip': 'text',
        '-webkit-text-fill-color': 'transparent',
        filter: 'drop-shadow(0px 0px 12px ' + col + '70)'
        // filter: 'drop-shadow(0px 0px 20px ' + col + '30)'
      }
    },

    randomTextColor() {
      let col: string = this.colors[Math.floor(Math.random() * this.colors.length)]

      return {
        color: col
      }
    },
  },

  computed: {
    header(): string {
      return this.headers[this.headerindex].keyword
    },

    caption(): string {
      return this.headers[this.headerindex].caption
    },

    keyword(): string {
      return this.headers[this.keywordindex].keyword
    },

    headerColor() {
      const col: string = this.colors[this.headerindex % this.colors.length]
      const col2: string = this.colors[(this.headerindex + 1) % this.colors.length]

      console.log(this.headerindex + ": " + col + " " + col2)

      //randomize:
      // console.log(Math.floor(Math.random() * this.colors.length))
      // let col: string = this.colors[Math.floor(Math.random() * this.colors.length)]
      // let col2: string = this.colors[Math.floor(Math.random() * this.colors.length)]

      return {
        color: col,
        background: 'linear-gradient(158deg, ' + col + ', ' + col2 + ')',
        'background-clip': 'text !important',
        '-webkit-background-clip': 'text',
        '-webkit-text-fill-color': 'transparent',
        filter: 'drop-shadow(0px 0px 20px ' + col + '40)'
      }
    },

    rainbow() {
      var prop = 'linear-gradient(158deg'

      for (var col of this.colors) {
        prop += ', ' + col
      }

      prop += ')'

      return {
        background: prop
      }
    }
  },

  beforeMount() {
    setInterval(() => {
      if ((this.headerindex + 1) < this.headers.length) {
        this.headerindex++
      } else {
        this.headerindex = 0
      }
    }, 2500)

    setInterval(() => {
      if ((this.keywordindex + 1) < this.headers.length) {
        this.keywordindex++
      } else {
        this.keywordindex = 0
      }
    }, 1000)
  }
})
