<template>
  <div id="router-container" class="container-fluid px-0">
    <router-view/>
  </div>

  <div class="row justify-content-center">

    <!-- <p class="col-11 col-md-2 footer">Privacy Policy</p>
    <p class="col-11 col-md-2 footer">Impressum</p> -->
    <a href="mailto:seemayr@me.com" class="col-11 footer">made by dominik :)</a>
    
  </div>
</template>

<style lang="sass">
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;400;500;700;900&display=swap')

html
  padding: 0
  margin: 0

body
  // overwrite bootstrap defaults:
  font-family: 'SF Pro Text', -apple-system, BlinkMacSystemFont, 'Roboto', 'Segoe UI',Helvetica, Arial, sans-serif !important
  background-color: black !important
  color: white !important
  line-height: 1.0 !important

  padding: 0
  margin: 0

#router-container
  padding: 0
  margin: 0

.row
  margin: 0 !important

.footer
  color: white
  text-decoration: none
  opacity: 0.2
  padding: 20px
  text-align: center
  cursor: pointer
  transition: all 0.2s

  &:hover
    opacity: 1.0
    color: #FF6A75
  
</style>
